import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain, enforceChains } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'


import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'

import { Store } from './deliJS/store.js'
import { Inventory } from './deliJS/inventory.js'
import { FarmgodCore, 
       basicItems, standardTokens, 
       contractConfigs, testItems, testTokens } from './deliJS/farmgod-core.js'

export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  const [activeChainId, setActiveChainId] = React.useState("")
  function handleChainChange(chainId) {
    console.log("handling chain change")
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));

    enforceChains(["Fantom","FantomTEST"], letItRip)
    setActiveChainId(chainId)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');


const letItRip = () => {

}   

  const [items, setItems] = React.useState({})
  const [stablecoin, setStablecoin] = React.useState("")
  const [itemKeys, setItemKeys] = React.useState([])

  React.useEffect(()=>{
    if (connected) {
      if (activeChainId == "0xfa2") {

      setItems(testItems)
      console.log(testItems)
      setStablecoin(testTokens["dai"]["address"])
      setItemKeys(Object.keys(testItems))
    } else {
      setItems(basicItems)
      console.log(basicItems)
      setStablecoin(standardTokens["dai"]["address"])
      setItemKeys(Object.keys(basicItems))
    }
    }
    
  }, [activeChainId])

  

  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
      <h2>Deliland Home Goods</h2>
      <Store 
        chainId={activeChainId} 
        connected={connected} 
        web3={web3} 
        items={items} 
        itemKeys={itemKeys}
        stablecoin={stablecoin}/>
      {itemKeys.map((abbr)=>
        <Inventory 
          chainId={activeChainId} 
          connected={connected} 
          web3={web3} 
          abbr={abbr}
          items={items} 
          itemKeys={itemKeys} />
        )}
      
        
        
      
    
      <Footer />
    </div>

  );
}

