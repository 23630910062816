import React from 'react';
import { Pu } from '../tools/Pu.js'
import { Joo } from '../tools/JooishWeb3.js'
import { FarmgodCore } from '../deliJS/farmgod-core.js'
import {formatTokenBalance} from '../tools/tokenUtilities'
import { Token } from '../tools/token.js'
import { suggestion } from '../tools/TokenSuggestion.js'

export const Wallet = (props) => {
  
  var web3 = props.web3
  const getBalanceStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "w" : "setW"
    const meat = "alletBalance"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

  const getSetBalanceStateString = (cbvar, tokenName ) => {
    return getBalanceStateString(tokenName) + "(" + cbvar + ")" 
  }

  const getTotalSupplyStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "t" : "setT"
    const meat = "otalSupply"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

  const getSetTotalSupplyStateString = (cbvar, tokenName ) => {
    return getTotalSupplyStateString(tokenName) + "(" + cbvar + ")" 
  }

  const getPoolBalanceStateString = (tokenName, chefName, isSet = true ) => {
    const prefix = (!isSet) ? "p" : "setP"
    const meat = "oolBalance"
    const suffix = "_" + tokenName + "_" + chefName
    return prefix + meat + suffix  
  }

const getSetPoolBalanceStateString = (cbvar, tokenName, chefName ) => {
    return getPoolBalanceStateString(tokenName, chefName) + "(" + cbvar + ")" 
  }

  const getDeadBalanceStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "d" : "setD"
    const meat = "eadBalance"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

const getSetDeadBalanceStateString = (cbvar, tokenName ) => {
    return getDeadBalanceStateString(tokenName) + "(" + cbvar + ")" 
  }

// walletBalance
const [walletBalance_gdeli, setWalletBalance_gdeli] = React.useState(0)
const [walletBalance_acre, setWalletBalance_acre] = React.useState(0)
const [walletBalance_yomopu, setWalletBalance_yomopu] = React.useState(0)
const [walletBalance_salt, setWalletBalance_salt] = React.useState(0)
const [walletBalance_water, setWalletBalance_water] = React.useState(0)


// totalSupply


const [totalSupply_gdeli, setTotalSupply_gdeli] = React.useState(0)
const [totalSupply_acre, setTotalSupply_acre] = React.useState(0)
const [totalSupply_yomopu, setTotalSupply_yomopu] = React.useState(0)
const [totalSupply_salt, setTotalSupply_salt] = React.useState(0)
const [totalSupply_water, setTotalSupply_water] = React.useState(0)



// maxSupply
const [maxSupply_echo, setMaxSupply_echo] = React.useState(0)

const [maxSupply_gdeli, setMaxSupply_gdeli] = React.useState(0)
const [maxSupply_acre, setMaxSupply_acre] = React.useState(0)
const [maxSupply_yomopu, setMaxSupply_yomopu] = React.useState(0)
const [maxSupply_salt, setMaxSupply_salt] = React.useState(0)
const [maxSupply_water, setMaxSupply_water] = React.useState(0)



// deadBalance


const [deadBalance_gdeli, setDeadBalance_gdeli] = React.useState(0)
const [deadBalance_acre, setDeadBalance_acre] = React.useState(0)
const [deadBalance_yomopu, setDeadBalance_yomopu] = React.useState(0)
const [deadBalance_salt, setDeadBalance_salt] = React.useState(0)
const [deadBalance_water, setDeadBalance_water] = React.useState(0)







// poolBalance
const [poolBalance_echo_acremaker, setPoolBalance_echo_acremaker] = React.useState(0)

const [poolBalance_gdeli_acremaker, setPoolBalance_gdeli_acremaker] = React.useState(0)
const [poolBalance_acre_acremaker, setPoolBalance_acre_acremaker] = React.useState(0)
const [poolBalance_yomopu_puchef, setPoolBalance_yomopu_puchef] = React.useState(0)
const [poolBalance_yomopu_bobachef, setPoolBalance_yomopu_bobachef] = React.useState(0)




// newer tokens
const [walletBalance_energ, setWalletBalance_energ] = React.useState(0)
const [totalSupply_energ, setTotalSupply_energ] = React.useState(0)
const [maxSupply_energ, setMaxSupply_energ] = React.useState(0)
const [deadBalance_energ, setDeadBalance_energ] = React.useState(0)
const [poolBalance_energ, setPoolBalance_energ] = React.useState(0)

const [walletBalance_penerg, setWalletBalance_penerg] = React.useState(0)
const [totalSupply_penerg, setTotalSupply_penerg] = React.useState(0)
const [maxSupply_penerg, setMaxSupply_penerg] = React.useState(0)
const [deadBalance_penerg, setDeadBalance_penerg] = React.useState(0)
const [poolBalance_penerg, setPoolBalance_penerg] = React.useState(0)

const [walletBalance_mgdeli, setWalletBalance_mgdeli] = React.useState(0)
const [totalSupply_mgdeli, setTotalSupply_mgdeli] = React.useState(0)
const [maxSupply_mgdeli, setMaxSupply_mgdeli] = React.useState(0)
const [deadBalance_mgdeli, setDeadBalance_mgdeli] = React.useState(0)
const [poolBalance_mgdeli, setPoolBalance_mgdeli] = React.useState(0)

const [walletBalance_ugu, setWalletBalance_ugu] = React.useState(0)
const [totalSupply_ugu, setTotalSupply_ugu] = React.useState(0)
const [maxSupply_ugu, setMaxSupply_ugu] = React.useState(0)
const [deadBalance_ugu, setDeadBalance_ugu] = React.useState(0)
const [poolBalance_ugu, setPoolBalance_ugu] = React.useState(0)

const [walletBalance_liggies, setWalletBalance_liggies] = React.useState(0)
const [totalSupply_liggies, setTotalSupply_liggies] = React.useState(0)
const [maxSupply_liggies, setMaxSupply_liggies] = React.useState(0)
const [deadBalance_liggies, setDeadBalance_liggies] = React.useState(0)
const [poolBalance_liggies, setPoolBalance_liggies] = React.useState(0)

const [walletBalance_sugarcane, setWalletBalance_sugarcane] = React.useState(0)
const [totalSupply_sugarcane, setTotalSupply_sugarcane] = React.useState(0)
const [maxSupply_sugarcane, setMaxSupply_sugarcane] = React.useState(0)
const [deadBalance_sugarcane, setDeadBalance_sugarcane] = React.useState(0)
const [poolBalance_sugarcane, setPoolBalance_sugarcane] = React.useState(0)

const [walletBalance_wheat, setWalletBalance_wheat] = React.useState(0)
const [totalSupply_wheat, setTotalSupply_wheat] = React.useState(0)
const [maxSupply_wheat, setMaxSupply_wheat] = React.useState(0)
const [deadBalance_wheat, setDeadBalance_wheat] = React.useState(0)
const [poolBalance_wheat, setPoolBalance_wheat] = React.useState(0)

const [walletBalance_gseed, setWalletBalance_gseed] = React.useState(0)
const [totalSupply_gseed, setTotalSupply_gseed] = React.useState(0)
const [maxSupply_gseed, setMaxSupply_gseed] = React.useState(0)
const [deadBalance_gseed, setDeadBalance_gseed] = React.useState(0)
const [poolBalance_gseed, setPoolBalance_gseed] = React.useState(0)

const [walletBalance_sugar, setWalletBalance_sugar] = React.useState(0)
const [totalSupply_sugar, setTotalSupply_sugar] = React.useState(0)
const [maxSupply_sugar, setMaxSupply_sugar] = React.useState(0)
const [deadBalance_sugar, setDeadBalance_sugar] = React.useState(0)
const [poolBalance_sugar, setPoolBalance_sugar] = React.useState(0)

const [walletBalance_brownsugar, setWalletBalance_brownsugar] = React.useState(0)
const [totalSupply_brownsugar, setTotalSupply_brownsugar] = React.useState(0)
const [maxSupply_brownsugar, setMaxSupply_brownsugar] = React.useState(0)
const [deadBalance_brownsugar, setDeadBalance_brownsugar] = React.useState(0)
const [poolBalance_brownsugar, setPoolBalance_brownsugar] = React.useState(0)

const [walletBalance_flour, setWalletBalance_flour] = React.useState(0)
const [totalSupply_flour, setTotalSupply_flour] = React.useState(0)
const [maxSupply_flour, setMaxSupply_flour] = React.useState(0)
const [deadBalance_flour, setDeadBalance_flour] = React.useState(0)
const [poolBalance_flour, setPoolBalance_flour] = React.useState(0)

const [walletBalance_wholeflour, setWalletBalance_wholeflour] = React.useState(0)
const [totalSupply_wholeflour, setTotalSupply_wholeflour] = React.useState(0)
const [maxSupply_wholeflour, setMaxSupply_wholeflour] = React.useState(0)
const [deadBalance_wholeflour, setDeadBalance_wholeflour] = React.useState(0)
const [poolBalance_wholeflour, setPoolBalance_wholeflour] = React.useState(0)

const [walletBalance_gcoil, setWalletBalance_gcoil] = React.useState(0)
const [totalSupply_gcoil, setTotalSupply_gcoil] = React.useState(0)
const [maxSupply_gcoil, setMaxSupply_gcoil] = React.useState(0)
const [deadBalance_gcoil, setDeadBalance_gcoil] = React.useState(0)
const [poolBalance_gcoil, setPoolBalance_gcoil] = React.useState(0)

const [walletBalance_gpoil, setWalletBalance_gpoil] = React.useState(0)
const [totalSupply_gpoil, setTotalSupply_gpoil] = React.useState(0)
const [maxSupply_gpoil, setMaxSupply_gpoil] = React.useState(0)
const [deadBalance_gpoil, setDeadBalance_gpoil] = React.useState(0)
const [poolBalance_gpoil, setPoolBalance_gpoil] = React.useState(0)

const [walletBalance_puju, setWalletBalance_puju] = React.useState(0)
const [totalSupply_puju, setTotalSupply_puju] = React.useState(0)
const [maxSupply_puju, setMaxSupply_puju] = React.useState(0)
const [deadBalance_puju, setDeadBalance_puju] = React.useState(0)
const [poolBalance_puju, setPoolBalance_puju] = React.useState(0)

const [walletBalance_momi, setWalletBalance_momi] = React.useState(0)
const [totalSupply_momi, setTotalSupply_momi] = React.useState(0)
const [maxSupply_momi, setMaxSupply_momi] = React.useState(0)
const [deadBalance_momi, setDeadBalance_momi] = React.useState(0)
const [poolBalance_momi, setPoolBalance_momi] = React.useState(0)

const [walletBalance_animal, setWalletBalance_animal] = React.useState(0)
const [totalSupply_animal, setTotalSupply_animal] = React.useState(0)
const [maxSupply_animal, setMaxSupply_animal] = React.useState(0)
const [deadBalance_animal, setDeadBalance_animal] = React.useState(0)
const [poolBalance_animal, setPoolBalance_animal] = React.useState(0)

const PU = {
  "acremaker": new Pu(web3, "acremaker"),
  "puchef": new Pu(web3, "puchef"),
  "bobachef": new Pu(web3, "bobachef")
}


const buildBatchOfBalanceRequests = () => {
  var j = new Joo(web3)
  var batch = new web3.BatchRequest();

  Object.keys(FarmgodCore).forEach(tokenName => {
    if (!FarmgodCore[tokenName]["hide"]) {
    batch.add(j.balanceRequest(
      FarmgodCore[tokenName]["address"],
      window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetBalanceStateString(res, tokenName))
      }
      ))
    batch.add(j.totalSupplyRequest(
      FarmgodCore[tokenName]["address"],
       window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetTotalSupplyStateString(res, tokenName))
      }
      ))
    batch.add(j.balanceOfDeadRequest(
      FarmgodCore[tokenName]["address"],
       window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetDeadBalanceStateString(res, tokenName))
      }
      ))

    if (typeof FarmgodCore[tokenName]["pools"] != 'undefined') {
      FarmgodCore[tokenName]["pools"].forEach(chefPidPair => {
         // [{"chef":"puchef", "pid":0}, {"chef":"bobachef","pid":0}]
         batch.add(
          PU[chefPidPair["chef"]].getPoolBalance(chefPidPair["pid"], 
            (x, res) => {
              console.log(res)
              eval(getSetPoolBalanceStateString(res[0], tokenName, chefPidPair["chef"]))
            })
         )
      })
    }
  }})

  batch.execute()
}

const hasPools = (pools) => {
  console.log(pools)
  console.log(typeof pools != 'undefined')
  return typeof pools != 'undefined'
}

const hasChef = (chef, pools) => {
  let out = false
  if (hasPools(pools)) {
    
    pools.forEach(pool => {
      if (pool["chef"] == chef) {
        out = true
      }  
    }) 
  }
  return out
}

const thePools = (tokenName) => FarmgodCore[tokenName]["pools"]
const hasThePools = (tokenName) => hasPools(thePools(tokenName))
const hasTheChef = (chef, tokenName) => {
  console.log(chef)
  console.log(tokenName)
  return (hasThePools(tokenName)) ? hasChef(chef, thePools(tokenName)) : false
}
const getFormattedPoolBalance = (chef, tokenName) => {
  return ( hasTheChef( chef, tokenName ) ) ?  
        formatTokenBalance(
          FarmgodCore[tokenName]["decimals"], 
          eval(getPoolBalanceStateString(
            tokenName, 
            chef, 
            false))) : "--"
}

const getPCTExisting = (tokenName) => {
  let x = eval(getTotalSupplyStateString(tokenName, false))
  let y = FarmgodCore[tokenName]["max"]

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  return formatTokenBalance(0, 100* x / Number(y))
}
const getExisting = (tokenName, format = true) => {
  let x = eval(getTotalSupplyStateString(tokenName, false))
  let y = eval(getDeadBalanceStateString(tokenName, false))

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  y = y / (10 ** FarmgodCore[tokenName]["decimals"])
  return (format) ? formatTokenBalance(0, x - y) : x - y
}

const getPCTBurnedOfCurrent = (tokenName) => {
  let y = eval(getTotalSupplyStateString(tokenName, false))
  let x = eval(getDeadBalanceStateString(tokenName, false))

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  y = y / (10 ** FarmgodCore[tokenName]["decimals"])
  return formatTokenBalance(0, 100* x / Number(y))
}
 const getTotalBalance = (tokenName, format = true) => {
  let x = eval(getBalanceStateString(tokenName, false))
  let y = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "acremaker", 
            false))} catch (error) {return 0}
  }
  let z = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "puchef", 
            false))} catch (error) {return 0}
  }
  let zz = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "bobachef", 
            false))} catch (error) {return 0}
  }



  return (format) ? formatTokenBalance(FarmgodCore[tokenName]["decimals"], x + y() + z() + zz()) :  x + y() + z() + zz()
}

const getYourPCTExistingSupply = (tokenName) => {
  return formatTokenBalance(FarmgodCore[tokenName]["decimals"], 100 * getTotalBalance(tokenName, false) / getExisting(tokenName, false) )
}


  const [triggered, setTriggered] = React.useState(false)
  React.useEffect(() => {
    if (props.connected && !triggered) {
      buildBatchOfBalanceRequests()
      setTriggered(true)
    }
  }, [props.connected, triggered])
  const [isOn, setIsOn] = React.useState(false);
  const toggleIsOn = () => {
    if (isOn) {
      setIsOn(false)
    } else {
      setIsOn(true)
    }
  }


  return (
  <div className={"wallet-container isOn--" + isOn}>
    <button onClick={toggleIsOn}>Toggle Wallet Details</button>
      <table>
      <tr className="headhead">
      <th ></th>
      <th className="bal" colspan="6">Your Balances</th>
      <th className="sup" colspan="6">Supply</th>
      <th className="tdtext" colspan="5">Context</th>
      
      
      </tr>
      <tr>
        <th className="tdtoken strong"></th>
        <th className="bal  strong special">Total Balance</th>
        <th className="bal pct">Your % of Existing Supply</th>
        <th className="bal  strong">Wallet Balance</th>
        <th className="bal">Farming Acres</th>
        <th className="bal">Farming Yomopu</th>
        <th className="bal">Staked on Boba</th>

        <th className="sup special">Existing Supply</th>
        
        <th className="sup">Max Supply</th>
        <th className="sup">Current Supply</th>
        <th className="sup pct">% Current</th>
        <th className="sup">Burned Supply</th>
        <th className="sup pct">% Burned</th>

        
         

        <th className="tdtext smallaf">Contract</th>
        <th className="tdtext">Scope</th>
        <th className="tdtext">Type</th>
        <th className="tdtext">Archetype</th>
        <th className="tdtext smallaf r">Tax</th>
        

       
      </tr>
        {Object.keys(FarmgodCore).map((tokenName, index) => 
          (!FarmgodCore[tokenName]["hide"]) ?
    <tr key={index} className={"tdtokenrow tdtokenrow--" + tokenName}>
      <td className="tdtoken strong" onClick={
        suggestion(
          FarmgodCore[tokenName]["address"], 
          FarmgodCore[tokenName]["name"], 
          FarmgodCore[tokenName]["decimals"], 
          FarmgodCore[tokenName]["staticimg"])
      }><Token name={tokenName} /></td>
      <td className="bal strong special">{getTotalBalance(tokenName)}</td>
      <td className="bal pct">{getYourPCTExistingSupply(tokenName)} %</td>
      <td className="bal strong">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getBalanceStateString(tokenName, false)))}</td>
      <td className="bal">{getFormattedPoolBalance("acremaker", tokenName)}</td>
      <td className="bal">{getFormattedPoolBalance("puchef", tokenName)}</td>
      <td className="bal">{getFormattedPoolBalance("bobachef", tokenName)}</td>
      <td className="sup special">{getExisting(tokenName)}</td>
      <td className="sup">{formatTokenBalance(0,FarmgodCore[tokenName]["max"])}</td>
      <td className="sup">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getTotalSupplyStateString(tokenName, false)))}</td>
      <td className="sup pct">{getPCTExisting(tokenName)} %</td>
      <td className="sup">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getDeadBalanceStateString(tokenName, false)))}</td>
      <td className="sup pct">{getPCTBurnedOfCurrent(tokenName)} %</td>
      
      
      <td className="tdtext smallaf"><a href={"https://ftmscan.com/token/" + FarmgodCore[tokenName]["address"]} target="_blank">Read</a></td>
      <td className="tdtext">{FarmgodCore[tokenName]["scope"]}</td>
      <td className="tdtext">{FarmgodCore[tokenName]["type"]}</td>
      
      <td className="tdtext">{FarmgodCore[tokenName]["archetype"]}</td>
      <td className="tdtext smallaf r">{FarmgodCore[tokenName]["tax"]}</td>
      
      
    
    </tr> : ""

      )}</table>
        </div> )
}
