import React from 'react';
import Web3 from 'web3';


import { chainMap, enforceChain } from '../tools/ChainTools.js'
import { useInterval } from '../tools/UseInterval.js'
import { Token } from '../tools/token.js'
import {formatTokenBalance} from '../tools/tokenUtilities'
import { Joo } from '../tools/JooishWeb3.js'
import { FarmgodCore, standardTokens, contractConfigs } from './farmgod-core.js'

export const Inventory = (props) => {
	var web3 = props.web3
	
	var items = props.items

	var [tract, setTract] = React.useState("");
	React.useEffect(()=> {
		console.log("Inventory ", props.abbr, "items changed",items)
		if (Object.keys(items).includes(props.abbr)) {
			setTract(new web3.eth.Contract(
			contractConfigs["basicitem"]["abi"], 
			items[props.abbr]["address"]))
			console.log("Inventory items changed",items)
		}
	}, [items]) 
	

	React.useEffect(() => {
		if (Object.keys(items).includes(props.abbr)) {
			getUserBalance()
			getItemId()
			getItemImg()
		}
	}, [tract])
	const [userBal, setUserBal] = React.useState(0)
	const getUserBalance = () => {
		if (window.ethereum.selectedAddress == null || tract == "") {
		} else {
	  tract.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
	    .then((res)=> {
	      setUserBal(res)
	      console.log("Inventory balance",res)
	    })
	}

	}
	const [activeIndex, setActiveIndex] = React.useState(0)
	const prev = () => {
	  
	  if (activeIndex > 0) {
	    setActiveIndex(activeIndex - 1)
	  }
	  
	}
	const next = () => {
	  
	  if (activeIndex < userBal - 1) {
	    setActiveIndex(activeIndex + 1)
	  }
	  
	}

	const [activeItem, setActiveItem] = React.useState(0);
	
	const getItemId = () => {
	  if (window.ethereum.selectedAddress == null || tract == "") {

	  } else {
	    tract.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, activeIndex).call({from: window.ethereum.selectedAddress})
	    .then((res) => {
	      setActiveItem(res)
	    })
	}
	  }
	  

	const [activeImg, setActiveImg] = React.useState("");

	React.useEffect(() => {
		getItemId()
	}, [activeIndex])
	React.useEffect(() => {
		getItemImg()
	}, [activeItem])

	const getItemImg = () => {
	  if (window.ethereum.selectedAddress == null || tract == "") {

	  } else {
	    console.log("getting img for: ",activeItem)
	 tract.methods.tokenURI(activeItem).call(
	        {from: window.ethereum.selectedAddress})
	      .then(
	        (res) => {
	          console.log(res)
	          let x = atob(res.replace(/^data:application\/(json);base64,/, ''));
	          x = JSON.parse(x)
	          console.log(x)
	          x = x.image
	          console.log(x)
	          //x = atob(x.replace(/^data:image\/svg\+xml;base64,/, ''))
	          //console.log(x)
	          setActiveImg(x)  
	        })
	  }
	}
	const [triggered, setTriggered] = React.useState(false)
	React.useEffect(() => {
		if (props.connected && !triggered) {
			console.log("Inventory Open")
		  console.log(props.abbr)
		  setTriggered(true)
		}
	}, [props.connected, triggered])
	const [isOpen, setIsOpen] = React.useState(false)
	const openInventory = () => {
		if (!isOpen) {
			setIsOpen(true)
		}
	}
	const closeInventory = () => {
		if (isOpen) {
			setIsOpen(false)
		}
	}
	const InvItem = (props) => (<div className="inventory-item" tid={props.tid}><img src={props.img} /></div>)
	return (
		<div onClick={openInventory} className={"inventory inventory--" + isOpen + " inventory-connected--" + props.connected}>
			<h3>Inventory: {(Object.keys(items).includes(props.abbr)) ? items[props.abbr]["name"] : ""}</h3>

			<div className="inventory-group">
				<div className="inventory-group__balance">You have {userBal} of these Items</div>
		            <InvItem img={activeImg} tid={activeItem}/>
		            <div className="inventory-nav">
		            <button className="btn" onClick={prev}>Previous</button>
		            <span>{activeIndex}</span>
		            <button className="btn" onClick={next}>Next</button>
		            </div>
		        <button className="close-btn" onClick={closeInventory}>Close</button>
			</div>

		</div>

		)
}