import { FarmgodCore } from '../deliJS/farmgod-core.js'
import { scannerBaseURLByChain } from '../tools/ChainTools.js'
import React from 'react'


const GdeliLinks = () => (
    <div>
        <a href="https://deliland.io" target="_blank">Deliland</a>
        <a href="https://salt.maxus.blog" target="_blank">Salt</a>
        <a href="https://water.maxus.blog" target="_blank">Water</a>
        <a href="https://acres.maxus.blog" target="_blank">Acres</a>
        <a href="https://yomopu.maxus.blog" target="_blank">Yomopu</a>
    </div>
    )

const FarmgodLinks = () => (
    <div>
        <a href="https://app.gitbook.com/@maxusog/s/maxus-multiverse/" target="_blank">Docs</a>
        <a href="https://snapshot.org/#/farmgod.eth" target="_blank">Governance</a>

        <a href="https://echo.maxus.blog" target="_blank">ECHO</a>      
        <a href="https://eyeoftruth.io" target="_blank">eyeoftruth</a>
        <a href="https://maxus.blog" target="_blank">Multiverse</a>

    </div>
)

const XdaiLinks = () => (
    <div>
        <a href="https://moonwasp.io" target="_blank">MOONWASP</a>
        <a href="https://tequilafarm.io" target="_blank">Tequila Farm</a>
    </div>
    )




export const Footer = (props) => {
    const [isOn, setIsOn] = React.useState(false)
    const toggleIsOn = () => {
    if (isOn) {
      setIsOn(false)
    } else {
      setIsOn(true)
    }
  }
    return(
    <div className={"footer isOn--" + isOn}>
        <button onClick={toggleIsOn}>Farmgod Links</button>
        <FarmgodLinks />
        <GdeliLinks />
        <p>This app is in like super-duper-early-pre-alpha mode. PUHHLLLEASSEEE do not ape your life savings into this. Use at your own risk, homie. Def not audited.</p>
      </div>
      )
    }