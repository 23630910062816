import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { chainMap, enforceChain } from '../tools/ChainTools.js'
import { useInterval } from '../tools/UseInterval.js'
import { Token } from '../tools/token.js'
import {formatTokenBalance} from '../tools/tokenUtilities'
import { Joo } from '../tools/JooishWeb3.js'
import { FarmgodCore, 
	     basicItems, standardTokens, 
	     contractConfigs, testItems, testTokens } from './farmgod-core.js'


export const Store = (props) => {
	//console.log(props)
	var web3 = props.web3
	var items = props.items
	var itemKeys = props.itemKeys
	var stablecoin = props.stablecoin

	const [isFocused, setIsFocused] = React.useState(false)
	const [focusedItem, setFocusedItem] = React.useState("smBOWLwLID")
	const openItemView = (abbr) => {
		return () => {
			//console.log(items)
			//console.log(abbr)
			setIsFocused(true)
			setFocusedItem(abbr)
		}
	}

	const [isApproving, setIsApproving] = React.useState(false)
	const [isMinting, setIsMinting] = React.useState(false)
	const openMintPender = (tier) => {

		return () => {
			
			var j = new Joo(web3)
			//approve (tokenAddress, spender, walletAddress, amount, callback = () => {})
			//allowance (tokenAddress, spender, walletAddress, callback = () => {})
			var cost = items[focusedItem]["base-cost"] * 10**tier
			console.log("cost",cost)
			cost = cost.toString()
			cost = cost.concat("0".repeat(18))
			j.allowance(
				stablecoin,
				items[focusedItem]["address"],
				window.ethereum.selectedAddress,
				(res) => {
					console.log(res)
					if (cost !== res.toString()) {
						setIsApproving(true)
						j.approve(
							stablecoin,
							items[focusedItem]["address"],
							window.ethereum.selectedAddress,
							cost,
							(res) => {
								setIsApproving(false)
								setIsMinting(true)
								var tract = new web3.eth.Contract(contractConfigs["basicitem"]["abi"], items[focusedItem]["address"])
								tract.methods.mint(tier).send({from: window.ethereum.selectedAddress})
									.then((res) => {
										setIsMinting(false)
									}).catch( (err) => {
										setIsMinting(false)
										console.log(err)
									})
							}
							).catch( (err) => {
								setIsApproving(false)
							})
					} else {
						setIsMinting(true)
						var abi = contractConfigs["basicitem"]["abi"]
						var itemAddy = items[focusedItem]["address"]
						var tract = new web3.eth.Contract(abi, itemAddy)
						console.log(abi)
						console.log(itemAddy)
						tract.methods.mint(tier).send({from: window.ethereum.selectedAddress})
							.then((res) => {
								setIsMinting(false)
							}).catch( (err) => {
										setIsMinting(false)
										console.log(err)
									})
					}
				}
				)
		}
		
	}
	const unFocus = () => {
		setIsFocused(false)
	}

	
	const [triggered, setTriggered] = React.useState(false)
	React.useEffect(() => {
		if (props.connected && !triggered) {
		  console.log("Store Open", props.chainId)

		  setTriggered(true)

		}
	}, [props.connected, triggered])

	const mintFakeDai = () => {
		setIsMinting(true)
		var tract = new web3.eth.Contract(testTokens["dai"]["abi"], testTokens["dai"]["address"])
		tract.methods.mint(window.ethereum.selectedAddress, "100000000000000000000000")
			.send({from: window.ethereum.selectedAddress})
			.then((res)=>{
				setIsMinting(false)
			}).catch((err)=>{
				setIsMinting(false)
				alert(err)
			})
	}
	return (
		<div className={"store store--" + props.connected}>
			{itemKeys.map((item) => 
				<button 
				className={"btn sbtn sbtn--" + !isFocused} 
				onClick={openItemView(item)}>
					{
						items[item]["name"]
					}
				</button>)
			}
			
			<div className={"item-view item-view--" + isFocused}>
				<h3>{(Object.keys(items).length > 0 ) ? items[focusedItem]["name"] : ""}</h3>
				{(props.chainId == "0xfa2") ? <button className="btn btn--faucet" onClick={mintFakeDai}>Mint Test DAI</button> : ""}
				<button className="btn btn--back" onClick={unFocus}>Back to Main Store</button>
				<div className="item item--standard">
					<div className="item__name">Standard Tier {(Object.keys(items).length > 0 ) ? items[focusedItem]["name"] : ""}</div>
					<div className="item__cost">{(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"] : ""} DAI</div>
					<button className="item__mint" onClick={openMintPender(0)}>Approve {(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"] : ""} DAI and Mint</button>
				</div>
				<div className="item item--master">
					<div className="item__name">Master Tier {(Object.keys(items).length > 0 ) ? items[focusedItem]["name"] : ""}</div>
					<div className="item__cost">{(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"]*10 : ""} DAI</div>
					<div className="item__multiplier">Provides {(Object.keys(items).length > 0 ) ? items[focusedItem]["multiplier"] : ""}x Masterwork probabilities</div>
					<button className="item__mint" onClick={openMintPender(1)}>Approve {(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"]*10 : ""} DAI and Mint</button>
				</div>
				<div className="item item--god">
					<div className="item__name">God Tier {(Object.keys(items).length > 0 ) ? items[focusedItem]["name"] : ""}</div>
					<div className="item__cost">{(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"]*100 : ""} DAI</div>
					<div className="item__multiplier">Provides {(Object.keys(items).length > 0 ) ? items[focusedItem]["multiplier"] : ""}x Legendary probabilities</div>
					<button className="item__mint" onClick={openMintPender(2)}>Approve {(Object.keys(items).length > 0 ) ? items[focusedItem]["base-cost"]*100 : ""} DAI and Mint</button>
				</div>
			</div>
			<div className={"pending pending--" + isApproving}>Approving</div>
			<div className={"pending pending--" + isMinting}>Minting</div>
		</div>
		)
} 

